<template>
  <div class="info-one">
    <van-cell-group title="基本资料">
      <van-field
        v-model="info1.cn_name"
        input-align="right"
        label="姓名(中文)"
        readonly
      />
      <van-field
        v-model="info1.en_name"
        input-align="right"
        label-width="12em"
        readonly
      >
        <template #left-icon>
          <div class="label van-field__label" style="width: 140px;">
            姓名(英文/拼音)<van-icon class="icon" name="info-o" @click.stop="handleNameTip" />
          </div>
        </template>
      </van-field>
      <van-field
        v-model="info1.live_addr"
        input-align="right"
        label="住址"
        readonly
      />
      <van-field
        v-model="info1.mailing_addr"
        input-align="right"
        label="通讯地址"
        readonly
      />
      <van-field
        v-model="info1.credentials_type"
        input-align="right"
        label="身份证件种类"
        right-icon="arrow"
        readonly
      />
      <van-field
        v-model="info1.credentials_num"
        input-align="right"
        label="身份证件号码"
        readonly
      />
      <van-field
        v-model="info1.issue_at"
        label="签发地"
        input-align="right"
        readonly
      />
      <van-field
        v-model="info1.marital_status"
        label="婚姻状况"
        input-align="right"
        right-icon="arrow"
        readonly
      />
      <van-field
        v-model="info1.sex"
        label="性别"
        input-align="right"
        right-icon="arrow"
        readonly
      />
      <van-field
        v-model="info1.educ"
        label="教育水平"
        input-align="right"
        right-icon="arrow"
        readonly
      />
      <van-field
        v-model="info1.born_addr"
        input-align="right"
        clearable
        label="出生地点"
        readonly
      />
      <van-field
        v-model="info1.born_time"
        label="出生日期"
        input-align="right"
        right-icon="arrow"
        readonly
      />
      <van-field
        label-width="7em"
        v-model="info1.citizenship"
        input-align="right"
        label="国籍/公民身份"
        readonly
      />
      <van-field
        v-model="info1.email"
        label="E-mail"
        input-align="right"
        readonly
      />
      <van-field
        v-model="info1.auth_type"
        label-width="10em"
        input-align="right"
        right-icon="arrow"
        readonly
      >
      <template #left-icon>
        <div class="label van-field__label" style="width: 140px;">
          身份认证方式<van-icon class="icon" name="info-o" @click.stop="handleTip" />
        </div>
      </template>
    </van-field>
      <van-field
        v-model="info1.hk_bank"
        label="开户行"
        input-align="right"
        readonly
      />
      <van-field
        v-model="info1.bank_account_name"
        input-align="right"
        label="账户名称(英文/拼音)"
        readonly
      />
      <van-field
        input-align="right"
        v-model="info1.bank_account_num"
        label="账户号码"
        readonly
      />
    </van-cell-group>
  </div>
  <div class="info-two">
    <van-cell-group title="工作资料">
      <van-field
        v-model="info2.work_state"
        label="工作状况"
        right-icon="arrow"
        readonly
        input-align="right"
      />
      <van-field
        v-if="info2.work_state === '其它'"
        type="textarea"
        label="说明"
        rows="2"
        autosize
        readonly
        v-model="info2.work_state_other"
      />
      <van-field
        v-model="info2.master_name"
        label="雇主名称"
        input-align="right"
        readonly
        v-if="info2.show"
      />
      <van-field
        v-model="info2.position"
        label="职位"
        input-align="right"
        readonly
        v-if="info2.show"
      />
      <van-field
        label-width="7em"
        v-model="info2.industry"
        label="行业/业务性质"
        input-align="right"
        right-icon="arrow"
        readonly
        v-if="info2.show"
      />
      <van-field
        v-model="info2.work_year"
        label="受雇年期(年)"
        input-align="right"
        readonly
        v-if="info2.show"
      />
      <van-field
        v-model="info2.office_tel"
        input-align="right"
        label="办公电话"
        readonly
        v-if="info2.show"
      />
      <van-field
        v-model="info2.office_addr"
        label="办公地址"
        input-align="right"
        readonly
        v-if="info2.show"
      />
    </van-cell-group>
    <van-cell-group title="财务资料">
      <van-field
        clearable
        v-model="info2.annual_salary"
        label="年薪 (港币)"
        input-align="right"
        readonly
      />
      <van-field
        label-width="7em"
        v-model="info2.net_asset"
        input-align="right"
        label="资产净值(港币)"
        readonly
      />
      <van-cell>
        <template #title>
          <span class="van-cell__title van-field__label">收入来源</span>
        </template>
        <template #extra>
        <van-checkbox-group class="check-group" v-model="info2.income_source">
            <van-checkbox name="薪金" shape="square" disabled>薪金</van-checkbox>
            <van-checkbox name="储蓄" shape="square" disabled>储蓄</van-checkbox>
            <van-checkbox name="租金" shape="square" disabled>租金</van-checkbox>
            <van-checkbox name="其它" shape="square" disabled>其它</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-cell>
      <van-field
        clearable
        v-model="info2.other_income"
        label="其它收入来源"
        input-align="right"
        v-if="info2.income_source && info2.income_source.indexOf('其它') > -1"
        readonly
      />
    </van-cell-group>
  </div>
  <div class="info-three">
    <van-cell-group title="投资目标及经验">
      <van-field
        v-model="info3.invest_knowledge"
        label="投资知识"
        right-icon="arrow"
        input-align="right"
        readonly
      />
      <van-cell value="投资目标">
        <template #extra>
        <van-checkbox-group class="check-group" v-model="info3.invest_target">
            <van-checkbox name="增值" shape="square" disabled>增值</van-checkbox>
            <van-checkbox name="收入" shape="square" disabled>收入</van-checkbox>
            <van-checkbox name="投机" shape="square" disabled>投机</van-checkbox>
            <van-checkbox name="保值" shape="square" disabled>保值</van-checkbox>
            <van-checkbox name="对冲" shape="square" disabled>对冲</van-checkbox>
            <van-checkbox name="其它" shape="square" disabled>其它</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-cell>
      <van-field
        v-if="info3.invest_target && info3.invest_target.indexOf('其它') > -1"
        v-model="info3.other_target"
        clearable
        label="其它投资目标"
        input-align="right"
      />
      <van-field
        v-model="info3.monthly_transaction_frequency"
        label="每月交易频率"
        input-align="right"
        right-icon="arrow"
        readonly
      />
      <van-field
        v-model="info3.stock_exp"
        label="股票投资经验"
        input-align="right"
        placeholder="请选择"
        right-icon="arrow"
        readonly
      />
      <van-field
        v-model="info3.futures_exp"
        label-width="9em"
        label="期货/期权投资经验"
        input-align="right"
        right-icon="arrow"
        readonly
      />
      <van-field
        v-model="info3.fund_exp"
        label="基金投资经验"
        input-align="right"
        right-icon="arrow"
        readonly
      />
      <van-field
        v-model="info3.bond_exp"
        label="债券投资经验"
        input-align="right"
        right-icon="arrow"
        readonly
      />
      <van-field
        v-model="info3.cip_exp"
        label-width="11em"
        label="结构性投资产品投资经验"
        input-align="right"
        right-icon="arrow"
        readonly
      />
    </van-cell-group>
  </div>
  <div class="info-four">
    <van-cell-group>
      <template #title>
        <div style="text-align: left;margin-bottom: 20px;">其它资料</div>
        <div style="text-align: left;">您是否此账户的最终受益拥有人</div>
      </template>
      <van-cell class="van-cell">
        <van-radio-group class="check-group" v-model="info4.benefit_self">
          <van-radio name="是" disabled>是</van-radio>
          <van-radio name="否" disabled>否</van-radio>
        </van-radio-group>
      </van-cell>
      <div v-if="info4.benefit_self === '否'">
        <van-field
          label-width="7em"
          v-model="info4.beneficiary"
          label="最终受益人姓名"
          input-align="right"
          readonly
        />
        <van-field
          label-width="9em"
          v-model="info4.beneficiary_id"
          input-align="right"
          label="最终受益人证件号码"
          readonly
        />
        <van-field
          label-width="8em"
          v-model="info4.beneficiary_tel"
          label="最终受益人电话"
          input-align="right"
          readonly
        />
        <van-field
          label-width="9em"
          v-model="info4.beneficiary_addr"
          label="最终受益人通讯地址"
          input-align="right"
          readonly
        />
      </div>
      <van-cell-group>
        <template #title>
          <div style="text-align: left">您是否与蜂投证券有限公司或其联营公司之董事或雇员有任何关系</div>
        </template>
        <van-cell class="van-cell">
          <van-radio-group
            class="check-group" v-model="info4.beevest_relationship">
            <van-radio name="是" disabled>是</van-radio>
            <van-radio name="否" disabled>否</van-radio>
          </van-radio-group>
        </van-cell>
        <div
          v-if="info4.beevest_relationship === '是'"
          class="text-lable"
        >姓名/关系</div>
        <van-field
          v-if="info4.beevest_relationship === '是'"
          type="textarea"
          rows="2"
          autosize
          v-model="info4.beevest_relationship_name"
          readonly
        />
      </van-cell-group>
      <van-cell-group>
        <template #title>
          <div style="text-align: left">您是否香港联合交易所有限公司或香港期货交易所有限公司参与者之雇员或任何根据证券期货条例的注册人士</div>
        </template>
        <van-cell class="van-cell">
          <van-radio-group
            class="check-group" v-model="info4.hk_exchange_relationship">
            <van-radio name="是" disabled>是</van-radio>
            <van-radio name="否" disabled>否</van-radio>
          </van-radio-group>
        </van-cell>
        <div
          v-if="info4.hk_exchange_relationship === '是'"
          class="text-lable"
        >名称/职位/中央编号</div>
        <van-field
          v-if="info4.hk_exchange_relationship === '是'"
          clearable
          maxlength="100"
          type="textarea"
          rows="2"
          autosize
          v-model="info4.hk_exchange_relationship_name"
          placeholder="请输入。多个之间用“；”隔开。如：张三/RO/222222；李四/RO/333333"
        />
      </van-cell-group>

      <van-cell-group>
        <template #title>
          <div style="text-align: left">
            您是否在蜂投证券有限公司已开设由您或您代表他人/团体/公司实益持有/拥有/运作或您拥有直接或间接利益的账户（无论个人/联名/公司/托管）
          </div>
        </template>
        <van-cell class="van-cell">
          <van-radio-group
            class="check-group" v-model="info4.beevest_account_already">
            <van-radio name="是" disabled>是</van-radio>
            <van-radio name="否" disabled>否</van-radio>
          </van-radio-group>
        </van-cell>
        <div v-if="info4.beevest_account_already === '是'" class="text-lable">账户姓名/账户号码</div>
        <van-field
          v-if="info4.beevest_account_already === '是'"
          clearable
          type="textarea"
          rows="2"
          autosize
          v-model="info4.beevest_account_already_name"
          maxlength="100"
          placeholder="请输入。多个之间用“；”隔开。如：张三/222222；李四/333333"
        />
      </van-cell-group>

      <van-cell-group>
        <template #title>
          <div style="text-align: left">
            您是否任何在交易所上市公司的主要股东、高级人员、董事
            <van-icon style="top: 1px" name="info-o" @click="handleTip4" />
          </div>
        </template>
        <van-cell class="van-cell">
          <van-radio-group
            class="check-group" v-model="info4.exchange_vip">
            <van-radio name="是" disabled>是</van-radio>
            <van-radio name="否" disabled>否</van-radio>
          </van-radio-group>
        </van-cell>
        <div v-if="info4.exchange_vip === '是'" class="text-lable">股票名称/股票代码</div>
        <van-field
          v-if="info4.exchange_vip === '是'"
          clearable
          type="textarea"
          rows="2"
          autosize
          maxlength="100"
          v-model="info4.exchange_vip_stock"
          placeholder="请输入。多个之间用“；”隔开。如：浦发银行/600000.SH；小米集团-W/01810.HK"
        />
      </van-cell-group>

      <van-cell-group>
        <template #title>
          <div style="text-align: left">您是否政界人士或直隶亲属</div>
        </template>
        <van-cell class="van-cell">
          <van-radio-group class="check-group" v-model="info4.politicians">
            <van-radio name="是" disabled>是</van-radio>
            <van-radio name="否" disabled>否</van-radio>
          </van-radio-group>
        </van-cell>
      </van-cell-group>

      <van-cell-group>
        <template #title>
          <div style="text-align: left">您是否与政界人士有不寻常密切的关系，并且受大众广泛地及公开地得悉</div>
        </template>
        <van-cell class="van-cell">
          <van-radio-group
            class="check-group" v-model="info4.politicians_relationship">
            <van-radio name="是" disabled>是</van-radio>
            <van-radio name="否" disabled>否</van-radio>
          </van-radio-group>
        </van-cell>
      </van-cell-group>

      <van-cell-group>
        <template #title>
          <div style="text-align: left">您是否曾被其他持牌机构拒绝进行业务关系</div>
        </template>
        <van-cell class="van-cell">
          <van-radio-group class="check-group" v-model="info4.has_reject">
            <van-radio name="是" disabled>是</van-radio>
            <van-radio name="否" disabled>否</van-radio>
          </van-radio-group>
        </van-cell>
      </van-cell-group>
      <van-cell-group>
        <template #title>
          <div style="text-align: left">您是否有破产纪录</div>
        </template>
        <van-cell class="van-cell">
          <van-radio-group class="check-group" v-model="info4.has_broked">
            <van-radio name="是" disabled>是</van-radio>
            <van-radio name="否" disabled>否</van-radio>
          </van-radio-group>
        </van-cell>
      </van-cell-group>
    </van-cell-group>
  </div>
  <div class="info-five">
    <van-cell-group>
      <template #title>
        <div style="text-align: left;">税务身份</div>
      </template>
      <van-field
        v-model="info5.is_usa"
        label="是否美国公民或美国税务居民"
        label-width="16em"
        input-align="right"
        right-icon="arrow"
        readonly
      />
      <div v-if="info5.is_usa === '否'">
        <van-field
          label-width="11em"
          v-model="info5.reside_addr"
          label="居留司法管辖国家/地区"
          input-align="right"
          readonly
        />
        <van-field
          v-model="info5.has_tax_no"
          label-width="18em"
          input-align="right"
          maxlength="100"
          right-icon="arrow"
          readonly
        >
          <template #left-icon>
            <div
              class="label van-field__label"
              style="color: gray;width: 160px;"
              @click.stop="showTexTip">
              是否有税务编号
              <van-icon style="display: inline; position: relative; top: 3px;" name="info-o" />
            </div>
          </template>
        </van-field>
        <van-field
          v-if="info5.has_tax_no === '是'"
          label-width="7em"
          v-model="info5.tax_no"
          input-align="right"
          label="税务编号"
          readonly
        />
        <van-field
          v-if="info5.has_tax_no === '否 原因B'"
          label-width="7em"
          v-model="info5.no_tax_no_reason"
          input-align="right"
          readonly
          label="原因"
        />
      </div>
    </van-cell-group>
  </div>
  <div class="assess">
    <div class="title">
      <div>衍生品认知评估</div>
      <div style="margin-top: 10px;">衍生投资产品的认知评估(包括但不限于ETF/牛熊证/窝轮/期货/期权等)</div>
    </div>
    <van-cell-group>
      <template #title>
       <div class="question">您是否有意进行衍生产品的买卖</div>
      </template>
      <van-radio-group
        v-model="assess.accept_derive"
        disabled
      >
        <van-radio name="是">是</van-radio>
        <van-radio name="否">否</van-radio>
      </van-radio-group>
    </van-cell-group>
    <div v-if="assess.accept_derive === '是'">
      <van-cell-group>
        <template #title>
        <div class="question">1.您是否曾接受有关衍生产品性质和风险的一般知识或修读相关课程?</div>
        </template>
        <van-radio-group v-model="assess.learned_derive">
          <van-radio name="是" disabled>是</van-radio>
          <van-radio name="否" disabled>否</van-radio>
        </van-radio-group>
      </van-cell-group>
      <van-cell-group>
        <template #title>
        <div class="question">2.您现时或过去是否从事与衍生产品有关的工作?</div>
        </template>
        <van-radio-group v-model="assess.work_with_derive">
          <van-radio name="是" disabled>是</van-radio>
          <van-radio name="否" disabled>否</van-radio>
        </van-radio-group>
      </van-cell-group>
      <van-cell-group>
        <template #title>
        <div class="question">3.您是否在过去三年曾买卖过至少五次任何衍生产品的交易 (不论是否在交易所买卖)?</div>
        </template>
        <van-radio-group v-model="assess.buyed_derive">
          <van-radio name="是" disabled>是</van-radio>
          <van-radio name="否" disabled>否</van-radio>
        </van-radio-group>
      </van-cell-group>
    </div>
  </div>
  <div class="major">
    <van-cell-group>
      <template #title>
        <div class="theme-color">
          <span>专业投资者选项</span>
        </div>
        <div class="title theme-color">
          <span>您是否专业投资者？</span>
        </div>
      </template>
      <van-cell>
        <van-radio-group
          v-model="major.major_invester"
          direction="horizontal"
          class="option"
        >
          <van-radio name="是" disabled>是</van-radio>
          <van-radio name="否" disabled>否</van-radio>
        </van-radio-group>
      </van-cell>
    </van-cell-group>
  </div>
  <div class="root">
    <van-cell-group title="">
      <div class="content" v-if="risk.pass_risk_tropism === '是'">
        <div>
          &nbsp;&nbsp;&nbsp;&nbsp;
          投资风险取向: {{risk.risk_tropism_assess}}
        </div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;
          说明: {{risk_tropism_assess_map[risk.risk_tropism_assess]}}
        </div>
        <br/>
        <div>
          &nbsp;&nbsp;&nbsp;&nbsp;
          匹配的产品风险等级：{{risk.risk_tropism_match}}
        </div>
        <div>
          &nbsp;&nbsp;&nbsp;&nbsp;
          说明：{{risk_tropism_match_map[risk.risk_tropism_match]}}
        </div>
      </div>
      <div class="content" v-else-if="risk.risk_tropism_assess_and_self_assess_match==='是'">
        <div>
          &nbsp;&nbsp;&nbsp;&nbsp;
          投资风险取向: {{risk.risk_tropism_assess}}
        </div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;
          您的投资风险评级不足以交易蜂投证券提供的所有投资产品。
        </div>
      </div>
      <div class="content" v-else>
        <div>
          &nbsp;&nbsp;&nbsp;&nbsp;
          您所选择的投资风险评级与根据您的选择算出来的评级不一致，请确认是否填写正确或联系蜂投证券的客服以便进一步了解您的需求。
        </div>
      </div>
      <div class="content" style="margin-top: 0px; padding-top: 0px">
        <div>
          &nbsp;&nbsp;&nbsp;&nbsp;
          评估日期：{{risk.assess_time}}
        </div>
      </div>
    </van-cell-group>
  </div>
</template>

<script>
import {
  Button, Dialog, Icon, CellGroup, Field, Cell,
  Checkbox, CheckboxGroup, RadioGroup, Radio,
} from 'vant';
import { useStore } from 'vuex';
import { onMounted, ref } from 'vue';
import httpApi from '../../utils/httpApi';

const alertMsg = `香港/合资格银行：需绑定香港本地银行或合资格司法管辖区银行（美国、新加坡、英国、马来西亚、澳大利亚、奥地利、比利时、加拿大、爱尔兰、以色列、意大利、挪威、葡萄牙、西班牙、瑞典、瑞士），若是专业投资者需要邮寄专业投资者证明。

线下见证：提交申请后，将有认证人员联系并进行线下认证，并需邮寄开户申请签字材料、身份证件复印件、住址证明、银行证明、专业投资者证明（仅限专业投资者）。`;
const alertMsg4 = '“主要股东”指，就一家公司而言，任何人士其于该公司之任何股东大会有权行使，或可控制行使，10%或以上的投票权。';
const texMsg = '如账户持有人是中国大陆/香港税务居民，税务编号是其中国大陆/香港身份证号码，不同国家/地区的税务识别号码规范及详情，请参阅国际经合组织官方网站。';
export default {
  name: 'Home',
  components: {
    [Button.name]: Button,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Icon.name]: Icon,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
  },
  data() {
    return {
      risk_tropism_assess_map: {
        保守: '倾向于价值下跌风险低的投资。您较关心保存投资价值多于获取资本回报。',
        轻度保守: '您倾向于价值下跌风险较低的投资。然而，您认同为了取得较高回报，必须面对一些风险，并准备承受投资出现一些价格波动。',
        均衡: '您愿意适度投资于增长型投资项目，同时知道它们的价格容易波动。您可以承受一些价格波动，但倾向避免大幅或频繁价格波动的可能性。',
        轻度进取: '您的风险承受能力高于平均，愿意为取得可能较高回报而接受较高的下跌风险。',
        进取: '您愿意甚至渴望为了取得可能较高的回报，而接受较高的下跌机会。',
      },
      risk_tropism_match_map: {
        低风险: '在投资期内损失资本的机率最低；预期回报波幅亦低。适合的投资者类别：一般愿意接受较低回报，借以尽量降低损失资本的机率。从损失及回报的波幅来看，投资者损失资本的风险相对轻微。',
        低至中等风险: '在投资期内损失资本的机率低；预期回报波幅中等偏低。适合的投资者类别：愿意承受的风险水平高于“低风险”投资。与风险较高的投资比较，投资者的资本相对不易受市场波动所影响，但有时可能会出现明显的损失。',
        中等风险: '在投资期内损失资本的机率属中等；预期回报波幅亦为中等。适合的投资者类别：愿意承受的风险水平高于保守型投资者，但似乎无意承受长期回报显著高于通胀率所附带的短期风险。',
        中等至高风险: '在投资期内损失资本的机率中等偏高；预期回报波幅中等偏高。适合的投资者类别：可承受较高的风险水平。投资者的资本不获保证，而且可能不时波动。偶尔可能录得大幅损失。',
        高风险: '在投资期内损失资本的机率高；预期回报波幅亦高。适合的投资者类别：可承受高风险水平。投资者的资本不 获保证，而且可能显著波动。损失幅度可能相当大。对短期损失尤其敏感的保守型投资者应限制其对这类投资 的持仓比重。基金风险评级将每半年进行检讨。',
      },
    };
  },
  methods: {
    handleTip() {
      Dialog.alert({
        title: '身份认证方式',
        messageAlign: 'left',
        message: alertMsg,
        confirmButtonColor: 'rgba(0,0,0,0.6)',
      });
    },
    handleNameTip() {
      Dialog.alert({
        title: '提示',
        messageAlign: 'left',
        message: '姓名（英文/拼音），所有字母都要大写，且姓与名之间要有空格。',
        confirmButtonColor: 'rgba(0,0,0,0.6)',
      });
    },
    handleTip4() {
      Dialog.alert({
        title: '说明',
        messageAlign: 'left',
        message: alertMsg4,
        confirmButtonText: '我知道了',
        confirmButtonColor: 'rgba(0,0,0,0.6)',
      });
    },
    showTexTip() {
      Dialog.confirm({
        title: '税务编号说明',
        messageAlign: 'left',
        message: texMsg,
        cancelButtonText: '关闭',
        confirmButtonText: '查看网站',
        confirmButtonColor: 'rgba(0,0,0,0.6)',
      }).then(() => {
        const href = 'http://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/tax-identification-numbers/';
        if (this.$store.state.user.platform === 'APP') {
          this.$util.callApp(`outsidewebview?url=${href}`);
        } else {
          window.open(href, '_blank');
        }
      }).catch(() => {});
    },
  },
  setup() {
    const info1 = ref({});
    const info2 = ref({});
    const info3 = ref({});
    const info4 = ref({});
    const info5 = ref({});
    const assess = ref({});
    const major = ref({});
    const risk = ref({});
    onMounted(async () => {
      const store = useStore();
      store.commit('setTitleValue', '开户资料详情');
      store.commit('setStepsShow', false);
      // 第一步
      const info1_result = await httpApi.getInfoStepOne();
      if (!info1_result) return;
      info1.value = info1_result.data;
      // 第二步
      const info2_result = await httpApi.getInfoStepTwo();
      if (!info2_result) return;
      info2_result.data.show = ['受雇', '自雇'].indexOf(info2_result.data.work_state) > -1;
      info2.value = info2_result.data;
      // 第三步
      const info3_result = await httpApi.getInfoStepThree();
      if (!info3_result) return;
      info3.value = info3_result.data;
      // 第四步
      const info4_result = await httpApi.getInfoStepFour();
      if (!info4_result) return;
      info4.value = info4_result.data;
      // 第五步
      const info5_result = await httpApi.getInfoStepFive();
      if (!info5_result) return;
      info5.value = info5_result.data;
      // 衍生产品
      const assess_result = await httpApi.getAsessIndex();
      if (!assess_result) return;
      assess.value = assess_result.data;
      // 专业投资者
      const major_result = await httpApi.getMajorOptionStepOne();
      if (!major_result) return;
      major.value = major_result.data;
      // 投资风险取向
      const risk_result = await httpApi.getRiskOrientationStepTwo();
      if (!risk_result) return;
      risk.value = risk_result.data;
    });
    return {
      info1,
      info2,
      info3,
      info4,
      info5,
      assess,
      major,
      risk,
    };
  },
};
</script>

<style lang="less">
  .info-one {
    text-align: left;
    .check-group {
      width: 200px;
      height: 80px;
      display: flex;
      justify-content: space-around;
      align-items: space-around;
      flex-wrap: wrap;
      & > div {
        flex-basis: 50%;
      }
    }
    .icon {
      display: inline;
      position: relative;
      top: 3px;
      left: 3px;
    }
  }
  .info-two {
    text-align: left;
    .check-group {
      width: 200px;
      height: 60px;
      display: flex;
      justify-content: space-around;
      align-items: space-around;
      flex-wrap: wrap;
      & > div {
        flex-basis: 50%;
      }
    }
  }
  .info-three {
    text-align: left;
    .check-group {
      width: 200px;
      height: 90px;
      display: flex;
      justify-content: space-around;
      align-items: space-around;
      flex-wrap: wrap;
      & > div {
        flex-basis: 50%;
      }
    }
  }
  .info-four {
    .text-lable {
      text-align: left;
      font-size: @font-size-2;
      margin-left: @space-2;
      margin-top: @space-3;
    }
    .check-group {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      .van-radio {
        flex-basis: 50%;
        margin: 4px;
      }
    }
  }
   .assess {
    padding-top: @space-2;
    .title {
      text-align: left;
      margin-left: 16px;
      color: @font-color-1;
      font-size: @font-size-3;
    }
    .question {
      text-align: left;
      color: @font-color-0;
    }
    .van-radio-group {
      padding-top: @space-3;
      display: flex;
      padding-left: @space-0;
      .van-radio {
        flex-basis: 50%;
        padding-bottom: @space-3;
      }
    }
  }
  .major {
    text-align: left;
    .title {
      margin-top: @space-1;
      color: @font-color-0;
    }
    .option {
      justify-content: space-around;
    }
    .info {
      margin-top: @space-1;
      padding-left: @space-2;
      font-size: @font-size-2;
    }
  }
  .root {
    text-align: left;
    margin-bottom: 40px;

    .image  {
      padding-top: @space-2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .info {
        font-size: @font-size-2;
        padding: @space-2;
      }
    }
    .content {
      margin-top: @space-2;
      padding: @space-2;
      background: @contentColor;
      font-size: @font-size-2;
    }

    .title {
      text-align: center;
    }

    .btn {
      width: 80%;
      margin: @space-0 auto;
      margin-bottom: 0;
    }

    .hidden {
      visibility: hidden;
    }
  }
</style>
